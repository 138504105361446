import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { DownIcon, FaqContainer, FaqContent, FaqH2 } from "./FaqElements";
import leaf from "../../assets/leafheading.svg";

const Faq = () => {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <FaqContainer id="faq">
      <FaqContent>
      <div style={{textAlign: "center"}}>
      <img src={leaf} className="fleaf"/>
      </div>
        <h2>FAQ</h2>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          style={{
            width: "100%",
            backgroundColor: "transparent",
            color: "white",
            fontWeight: "bold",
            borderBottom: "1px solid white",
            borderRadius: 0,
            padding: "10px 0",
          }}
        >
          <AccordionSummary
            style={{ color: "var(--accent)" }}
            expandIcon={<DownIcon />}
            aria-controls="panel1a-content"
          >
            <Typography
              style={{
                fontSize: "larger",
                lineHeight: "22px",
                fontWeight: "bold",
              }}
            >
              {" "}
              What is an NFT?{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ fontSize: "larger", lineHeight: "22px" }}>
              It's short for non-fungible token, which means it's a rare piece
              of art that you only own.It's like trading cards.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          style={{
            width: "100%",
            backgroundColor: "transparent",
            color: "white",
            fontWeight: "bold",
            borderBottom: "1px solid white",
            borderRadius: 0,
            padding: "10px 0",
          }}
        >
          <AccordionSummary
            style={{ color: "var(--accent)" }}
            expandIcon={<DownIcon />}
            aria-controls="panel1a-content"
          >
            <Typography
              style={{
                fontSize: "larger",
                lineHeight: "22px",
                fontWeight: "bold",
              }}
            >
              {" "}
              How does I use Metamask?{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ fontSize: "larger", lineHeight: "22px" }}>
              Really simple, you can go directly here to show you how
              https://metamask.io/
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          style={{
            width: "100%",
            backgroundColor: "transparent",
            color: "white",
            fontWeight: "bold",
            borderBottom: "1px solid white",
            borderRadius: 0,
            padding: "10px 0",
          }}
        >
          <AccordionSummary
            style={{ color: "var(--accent)" }}
            expandIcon={<DownIcon />}
            aria-controls="panel1a-content"
          >
            <Typography
              style={{
                fontSize: "larger",
                lineHeight: "22px",
                fontWeight: "bold",
              }}
            >
              {" "}
              How do I fund my Metamask wallet?{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ fontSize: "larger", lineHeight: "22px" }}>
              You will need some Ethereum. You can buy it on Coinbase or
              straight from your Metamask Wallet.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          style={{
            width: "100%",
            backgroundColor: "transparent",
            color: "white",
            fontWeight: "bold",
            borderBottom: "1px solid white",
            borderRadius: 0,
            padding: "10px 0",
          }}
        >
          <AccordionSummary
            style={{ color: "var(--accent)" }}
            expandIcon={<DownIcon />}
            aria-controls="panel1a-content"
          >
            <Typography
              style={{
                fontSize: "larger",
                lineHeight: "22px",
                fontWeight: "bold",
              }}
            >
              {" "}
              How do I know what Badger Buddie I get when I mint?{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ fontSize: "larger", lineHeight: "22px" }}>
              That's great thing about this, it's the luck of the draw.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          style={{
            width: "100%",
            backgroundColor: "transparent",
            color: "white",
            fontWeight: "bold",
            borderBottom: "1px solid white",
            borderRadius: 0,
            padding: "10px 0",
          }}
        >
          <AccordionSummary
            style={{ color: "var(--accent)" }}
            expandIcon={<DownIcon />}
            aria-controls="panel1a-content"
          >
            <Typography
              style={{
                fontSize: "larger",
                lineHeight: "22px",
                fontWeight: "bold",
              }}
            >
              {" "}
              How are the Badger Buddiez generated?{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ fontSize: "larger", lineHeight: "22px" }}>
              From Mo the maverick, each badger trait was drawn on a computer
              and than randomly generated.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          style={{
            width: "100%",
            backgroundColor: "transparent",
            color: "white",
            fontWeight: "bold",
            borderBottom: "1px solid white",
            borderRadius: 0,
            padding: "10px 0",
          }}
        >
          <AccordionSummary
            style={{ color: "var(--accent)" }}
            expandIcon={<DownIcon />}
            aria-controls="panel1a-content"
          >
            <Typography
              style={{
                fontSize: "larger",
                lineHeight: "22px",
                fontWeight: "bold",
              }}
            >
              {" "}
              What are the benefits of owning a Badger Buddie?{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ fontSize: "larger", lineHeight: "22px" }}>
              You get to own a great piece of artwork. You also get lots of
              perks from owning a bager Buddie, just look at our roadmap for
              details.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </FaqContent>
    </FaqContainer>
  );
};

export default Faq;
