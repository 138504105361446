import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";

import * as s from "./styles/globalStyles";
import { StyledButton, StyledRoundButton} from "./components/MintElements";

import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import About from "./components/About";
import RaritySlide from "./components/RaritySlide";
import Gallery from "./components/Gallery Slider";
import Roadmap from "./components/RoadMap";
import Team from "./components/Team";
import Faq from "./components/Faq";
import Footer from "./components/Footer";
import mybb from "./assets/placeholderbb.svg";
import refresh from "./assets/refresh logo.svg";
import opensea from "./assets/openseabutton.svg";

import leaf from "./assets/leafheading.svg";
 

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...${input.substring(input.length, input.length - 3)}` : input;


function App() {
  var placeholder = [{badger:<><img src={mybb} className="myNFT"/><img src={mybb} className="myNFT"/><img src={mybb} className="myNFT"/><img src={mybb} className="myNFT"/><img src={mybb} className="myNFT"/><img src={mybb} className="myNFT"/><img src={mybb} className="myNFT"/><img src={mybb} className="myNFT"/></>}]
 
  const dispatch = useDispatch();
  const [mybblist, setSelected] = useState(placeholder[0].badger);
  const [isOpen, setIsOpen] = useState(false);
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your BB.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
      name();
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 8) {
      newMintAmount = 8;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
     
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    getConfig();
  }, []);

 

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  

const name = ()=>{
  var im = []; 
  var i = 0;
  for ( var i = 0;i< data.cost.length;i++){
 
 im.push(
    <img src={"https://ipfs.io/ipfs/QmPBfdUq6kBk2zvRJLaBsofmceaUk11s7tmbECj4z7erGi/"+data.cost[i]+".png" } className="myNFT" 
      />);
 };
 for( var j = data.cost.length ; j<8; j++ ){
   im.push(
<img src={mybb} className="myNFT"/>
   );
 };
setSelected(im);
};



  return (
    <s.Screen>
 <Sidebar isOpen={isOpen} toggle={toggle} />
 <Navbar toggle={toggle}/>
 <About />
 <RaritySlide />
 <Gallery />
 <Roadmap />
 
 <div className="mint" >
   <div   style={{width:"100%",textAlign:' -webkit-center'}}>
<div className="m m1">

{ window.ethereum ?
           ( web3.currentProvider.selectedAddress == null ? <h4>Please Connect Your MetaMask Wallet.</h4> : 

            blockchain.account === "" ||
            blockchain.smartContract === null ? (
            <s.Container ai={"center"} jc={"center"}>
              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--accent-text)",
                }}
              >
                Connect to the {CONFIG.NETWORK.NAME} network.
              </s.TextDescription>
              <s.SpacerSmall />
              <StyledButton className="button-primary"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                  
                }}
              >
                CONNECT
              </StyledButton>
              {blockchain.errorMsg !== "" ? (
                <>
                  <s.SpacerSmall />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                    }}
                  >
                    {blockchain.errorMsg}
                  </s.TextDescription>
                </>
               ): null}
           </s.Container> ): 

            (data.totalSupply == 0 ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> :

           <>
           
           {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
               <a href="https://opensea.io" target={"_blank"}>
                <img src={opensea} className="long"/>
                </a>
              </>
            ):(

              data.status == true ? <h3>Minting is paused</h3> :

            <>
             <h2>{data.totalSupply} / {CONFIG.MAX_SUPPLY}</h2>
            <s.TextTitle
            style={{ textAlign: "center", color: "var(--accent-text)" }}
          >
            1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
            {CONFIG.NETWORK.SYMBOL}.
          </s.TextTitle>
          <s.SpacerXSmall />
          <s.TextDescription
            style={{ textAlign: "center", color: "var(--accent-text)" }}
          >
            Excluding gas fees.
          </s.TextDescription>
          <s.SpacerSmall />
        
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--accent-text)",
            }}
          >
            {feedback}
          </s.TextDescription>
          <s.SpacerMedium />
          <s.Container ai={"center"} jc={"center"} fd={"row"}>
            <StyledRoundButton className="button-primary"
              style={{ lineHeight: 0.4 }}
              disabled={claimingNft ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                decrementMintAmount();
              }}
            >
              -
            </StyledRoundButton>
            <s.SpacerMedium />
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--accent-text)",
              }}
            >
              {mintAmount}

            </s.TextDescription>
            <s.SpacerMedium />
            <StyledRoundButton className="button-primary"
              disabled={claimingNft ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                incrementMintAmount();
              }}
            >
              +
            </StyledRoundButton>
          </s.Container>
          <s.SpacerSmall />
          <s.Container ai={"center"} jc={"center"} fd={"row"}>
            <StyledButton className="button-primary"
              disabled={claimingNft ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                claimNFTs();
                getData();
              }}
            >
              {claimingNft ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : "BUY"}
            </StyledButton><br />
           
          </s.Container>
          <br />
        
        
            </>
            )
            }

            </>
             ) ) : (<h4>Install MetaMask</h4>) }

                             
</div>
<div className="m m2">
  <div style={{    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'}}>

<div style={{textAlign: "center"}}>
      <img src={leaf} className="budleaf"/>
      </div>
<h2 style={{width: 'auto'}} >Your Buddiez</h2>
<div onClick={name} className=" round"><img src={refresh}/> </div>
</div>
<p style={{marginBottom:'20px'}}>Click Refresh Button to see your BB. It may take 5-10 mins to display recently minted BB.<br/> Click button below to see it on OpenSea. </p>
<a href="https://opensea.io/collection/badger-buddiez" target={"_blank"}>
              <img src={opensea} className="long"/>
                </a>
<div className="collection">{mybblist}</div>
</div>
</div>
</div>



   
     <Team />
      <Faq />
      <Footer />
    </s.Screen>
  );
}

export default App;
