import styled from "styled-components";
import { FaChevronDown } from "react-icons/fa";

export const FaqContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px;
  height: 800px;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 768px) {
    height: 1000px;
  }

  @media screen and (max-width: 480px) {
    height: 1000px;
  }
`;

export const DownIcon = styled(FaChevronDown)`
  color: #fff;
  font-size: 18px;
`;

export const FaqContent = styled.div`
  z-index: 3;
  width: -webkit-fill-available;
  max-width: 1100px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FaqH2 = styled.h2`
  color: #fff;
  font-size: 48px;
  text-align: center;
  padding: 30px;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;
