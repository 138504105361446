import React, { useEffect, useRef, useState } from "react";

import TimelineObserver from "react-timeline-animation";
import { fireConfetti } from "./confetti";
import { FaCheck } from "react-icons/fa";
import leaf from "../../assets/leafheading.svg";

const Timeline = ({ setObserver, callback }) => {
  const [message1, setMessage1] = useState("");
  const [message2, setMessage2] = useState("");
  const [message3, setMessage3] = useState("");
  const [message4, setMessage4] = useState("");
  const [message5, setMessage5] = useState("");
  const [message6, setMessage6] = useState("");
  const [message7, setMessage7] = useState("");
  const [message8, setMessage8] = useState("");
  const [message9, setMessage9] = useState("");
  const [message10, setMessage10] = useState("");
  const [message11, setMessage11] = useState("");
  const [message12, setMessage12] = useState("");
  const [message13, setMessage13] = useState("");

  const timeline1 = useRef(null);
  const timeline2 = useRef(null);
  const timeline3 = useRef(null);
  const timeline4 = useRef(null);
  const timeline5 = useRef(null);
  const timeline6 = useRef(null);
  const timeline7 = useRef(null);
  const timeline8 = useRef(null);
  const timeline9 = useRef(null);
  const timeline10 = useRef(null);
  const timeline11 = useRef(null);
  const timeline12 = useRef(null);
  const timeline13 = useRef(null);
  const circle1 = useRef(null);
  const circle2 = useRef(null);
  const circle3 = useRef(null);
  const circle4 = useRef(null);
  const circle5 = useRef(null);
  const circle6 = useRef(null);
  const circle7 = useRef(null);
  const circle8 = useRef(null);
  const circle9 = useRef(null);
  const circle10 = useRef(null);
  const circle11 = useRef(null);
  const circle12 = useRef(null);
  const circle13 = useRef(null);

  const someCallback = () => {
    setMessage1("Create a list and find artists for NFT projects and build a team for NFT projects.");
    
  };

  const someCallback2 = () => {
    setMessage2("Create list of utilities and benefits for NFT holders. Create and launch Twitter, Telegram, Discord, Instagram, Tick Tock, Twitch, and YouTube.");
    
  };

  const someCallback3 = () => {
    setMessage3("Write smart contract which gives all NFT projects from 808 and Coinwarp an exclusive membership pass to the 808’s ecosystem. The 808’s utility coin will receive a percentage of every NFT sale and go into liquidity. A percentage of every first sale, percentage of royalties, percentage of every buy / sell transaction, percentage of every swap, percentage of mining and staking, percentage of all fees charged by 808Blockchain / Coinwarp, and percentage of all NFT’s purchased by the community wallet. There will be a percentage deposited into liquidity and into the buy back burn program for 808’s utility token. A percentage will go into the community crypto wallet which will be used to purchase 808’s / Coinwarp’s stable coin which then will be sent to all NFT holders and utility coin holders. To receive rewards, each holder must hold onto their NFT and/or utility token for a minimum of ninety days. Holder’s timeline will be assessed at the beginning of every ninety-day cycle. All rewards that are not distributed to holders because they missed their cycle will be absorbed into the rewards pool and redistributed to all holders who successfully completed all requirements to receive rewards. A percentage of all rewards going to token holders will be sent to NFT community wallet which will be used for purchasing rare and potential revenue making NFT’s and investments. This specific wallet will be community ran and all purchased transactions will be voted on and approved by the community.");
    
  };

  const someCallback4 = () => {
    setMessage4("Launch first NFT project.");
    
  };

  const someCallback5 = () => {
    setMessage5("Launch second NFT project.");
    
  };

  const someCallback6 = () => {
    setMessage6("Launch third NFT project.");
    
  };

  const someCallback7 = () => {
    setMessage7("Create our Charity wallet");
    
  };

  const someCallback8 = () => {
    setMessage8("Launch utility token for 808’s ecosystem.");
    
  };

  const someCallback9 = () => {
    setMessage9("Launch blockchain bridge.");
    
  };

  const someCallback10 = () => {
    setMessage10("Launch ETH DeFi.");
    
  };

  const someCallback11 = () => {
    setMessage11("Launch Binance smart-chain DeFi.");
    
  };

  const someCallback12 = () => {
    setMessage12("Whitelist all 808’s for membership to the new exchange and NFT marketplace ran on the Coinwarp blockchain.");
    
  };

  const someCallback13 = () => {
    setMessage13("Roll out VIP access to exclusive pre-sale collaborations.");
    fireConfetti();
  };

  useEffect(() => {
    setObserver(timeline1.current);
    setObserver(timeline2.current);
    setObserver(timeline3.current);
    setObserver(timeline4.current);
    setObserver(timeline5.current);
    setObserver(timeline6.current);
    setObserver(timeline7.current);
    setObserver(timeline8.current);
    setObserver(timeline9.current);
    setObserver(timeline10.current);
    setObserver(timeline11.current);
    setObserver(timeline12.current);
    setObserver(timeline13.current);
 
    setObserver(circle1.current, someCallback);
    setObserver(circle2.current, someCallback2);
    setObserver(circle3.current, someCallback3);
    setObserver(circle4.current, someCallback4);
    setObserver(circle5.current, someCallback5);
    setObserver(circle6.current, someCallback6);
    setObserver(circle7.current, someCallback7);
    setObserver(circle8.current, someCallback8);
    setObserver(circle9.current, someCallback9);
    setObserver(circle10.current, someCallback10);
    setObserver(circle11.current, someCallback11);
    setObserver(circle12.current, someCallback12);
    setObserver(circle13.current, someCallback13);

  }, []);

  return (
    <div className="wrapper">
      <div id="timeline1" ref={timeline1} className="timeline" />
      <div className="circleWrapper">
        <div id="circle1" ref={circle1} className="circle">
          <FaCheck />
        </div>
        <div className="message">
          1.
          <p>
          {message1}
          </p>
          
        </div>
      </div>
      <div id="timeline2" ref={timeline2} className="timeline" />
      <div className="circleWrapper">
        <div id="circle2" ref={circle2} className="circle">
          <FaCheck />
        </div>
        <div className="message">
          
        2.
          <p>
          {message2}
          </p>
        </div>
      </div>
      <div id="timeline3" ref={timeline3} className="timeline " />
      <div className="circleWrapper">
        <div id="circle3" ref={circle3} className="circle">
          <FaCheck />
        </div>
        <div className="message">
          
        3.
          <p>
          {message3}
          </p>
        </div>
      </div>
      <div id="timeline4" ref={timeline4} className="timeline longtext" />
      <div className="circleWrapper">
        <div id="circle4" ref={circle4} className="circle">
          <FaCheck />
        </div>
        <div className="message">
          
        4.
          <p>
          {message4}
          </p>
        </div>
      </div>
      <div id="timeline5" ref={timeline5} className="timeline" />
      <div className="circleWrapper">
        <div id="circle5" ref={circle5} className="circle">
          <FaCheck />
        </div>
        <div className="message">
          
       5.
          <p>
          {message5}
          </p>
        </div>
      </div>
      <div id="timeline6" ref={timeline6} className="timeline" />
      <div className="circleWrapper">
        <div id="circle6" ref={circle6} className="circle">
          <FaCheck />
        </div>
        <div className="message">
          
       6.
          <p>
          {message6}
          </p>
        </div>
      </div>
      <div id="timeline7" ref={timeline7} className="timeline" />
      <div className="circleWrapper">
        <div id="circle7" ref={circle7} className="circle">
          <FaCheck />
        </div>
        <div className="message">
          
       7.
          <p>
          {message7}
          </p>
        </div>
      </div>
      <div id="timeline8" ref={timeline8} className="timeline" />
      <div className="circleWrapper">
        <div id="circle8" ref={circle8} className="circle">
          <FaCheck />
        </div>
        <div className="message">
          
       8.
          <p>
          {message8}
          </p>
        </div>
      </div>
      <div id="timeline9" ref={timeline9} className="timeline" />
      <div className="circleWrapper">
        <div id="circle9" ref={circle9} className="circle">
          <FaCheck />
        </div>
        <div className="message">
          
       9.
          <p>
          {message9}
          </p>
        </div>
      </div>
      <div id="timeline10" ref={timeline10} className="timeline" />
      <div className="circleWrapper">
        <div id="circle10" ref={circle10} className="circle">
          <FaCheck />
        </div>
        <div className="message">
          
       10.
          <p>
          {message10}
          </p>
        </div>
      </div>
      <div id="timeline11" ref={timeline11} className="timeline" />
      <div className="circleWrapper">
        <div id="circle11" ref={circle11} className="circle">
          <FaCheck />
        </div>
        <div className="message">
          
       11.
          <p>
          {message11}
          </p>
        </div>
      </div>
      <div id="timeline12" ref={timeline12} className="timeline" />
      <div className="circleWrapper">
        <div id="circle12" ref={circle12} className="circle">
          <FaCheck />
        </div>
        <div className="message">
          
       12.
          <p>
          {message12}
          </p>
        </div>
      </div>
      <div id="timeline13" ref={timeline13} className="timeline" />
      <div className="circleWrapper">
        <div id="circle13" ref={circle13} className="circle">
          <FaCheck />
        </div>
        <div className="message">
          
       13.
          <p>
          {message13}
          </p>
        </div>
      </div>
    </div>
    
    
  );
};

const Roadmap = () => {
 

  return (
    <div className="roadmap" id="roadmap">
      <div style={{textAlign: "center"}}>
      <img src={leaf} className="roadleaf"/>
      </div>
      <h2>RoadMap</h2>
      <div className="stub1">
      Utility and benefits to our holders / community comes first. 
      Our goal is to make our NFTs be one of the best in the universe. 
      We are working on several NFT projects which will give our holders not only 
      rarity with each NFT they hold, but as well as a lot of new Lore concepts. 
      The drop release date will be announced as soon as the project's quality and technical aspects are fully progressed. 
      We also need to work hard with our community to make Discord and Twitter even cooler with new additions throughout time.
      </div>
      <div className="roadcontainer">
        <TimelineObserver
          initialColor="#e5e5e5"
          fillColor="#cfad91"
          handleObserve={(setObserver) => (
            <Timeline
             
              className="timeline"
              setObserver={setObserver}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Roadmap;
