import React from "react";

import t1 from "../../assets/bb1.webp";
import t2 from "../../assets/bb2.webp";
import t3 from "../../assets/bb3.webp";
import leaf from "../../assets/leafheading.svg";

const Team = () => {
  return (
    <div className="team" id="team">
       <div style={{textAlign: "center", width:"100%"}}>
      <img src={leaf} className="tleaf"/>
      </div>
      <h2>Team</h2>
      <div className="member">
        <img src={t1} />
        <h3>Smokey Ace</h3>
        <p>Always brainstorming</p>
      </div>
      <div className="member">
        <img src={t2} />
        <h3>Laid back Lou</h3>
        <p>Making moves in the tropics</p>
      </div>
      <div className="member">
        <img src={t3} />
        <h3>Mo the Maverick</h3>
        <p>To the moon!</p>
      </div>
    </div>
  );
};

export default Team;
