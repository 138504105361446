import React, { Component } from "react";
import Slider from "react-slick";
import rrock from "../../assets/rightrock.png";
import mrrock from "../../assets/mrightrock.png";
import lrock from "../../assets/leftrock.png";
import leaf from "../../assets/leafheading.svg";

const RaritySlide = () => {
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 500,
    pauseOnHover: false,
    cssEase: "ease",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (

    // <div className="raritysec"  id="rarity">
     
      
         <div className="raritySlider" id="rarity">
           <div style={{textAlign: "center"}}>
      <img src={leaf} className="rareleaf"/>
      </div>
           <h2>Rarity</h2>
         <img src={lrock} className="lrock"/>
        <div className="rrockcontainer"> <img src={rrock} className="rrock"/> <img src={mrrock} className="mrrock"/></div>
      <Slider {...settings}>
        <div className="slideItem">
          <div className="ritem">
            <div className="rtitle">Background</div>
            <div className="info">10</div>
          </div>
        </div>
        <div className="slideItem">
          <div className="ritem">
            <div className="rtitle">Skins</div>
            <div className="info">10</div>
          </div>
        </div>
        <div className="slideItem">
          <div className="ritem">
            <div className="rtitle">Clothes</div>
            <div className="info">11</div>
          </div>
        </div>
        <div className="slideItem">
          <div className="ritem">
            <div className="rtitle">Headwears</div>
            <div className="info">10</div>
          </div>
        </div>
        <div className="slideItem">
          <div className="ritem">
            <div className="rtitle">Mouths</div>
            <div className="info">9</div>
          </div>
        </div>
        <div className="slideItem">
          <div className="ritem">
            <div className="rtitle">Eyes</div>
            <div className="info">10</div>
          </div>
        </div>
    
      </Slider>
    </div>
    // </div>
  );
};

export default RaritySlide;
