import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import badger1 from "../../assets/bb1.webp";
import badger2 from "../../assets/bb2.webp";
import badger3 from "../../assets/bb3.webp";
import badger4 from "../../assets/bb4.webp";
import badger5 from "../../assets/bb5.webp";
import badger6 from "../../assets/bb6.webp";
import badger7 from "../../assets/bb7.webp";
import badger8 from "../../assets/bb8.webp";
import badger9 from "../../assets/bb9.webp";
import badger10 from "../../assets/bb10.webp";
import front from "../../assets/front.png";
import back from "../../assets/back.png";
import que from "../../assets/questionmark.svg";
import lleaf from "../../assets/leftleafs.svg";
import mrleaf from "../../assets/mrightleafs.svg";
import rleaf from "../../assets/rightleafs.svg";


const About = () => {
  const [selected, setSelected] = useState(badger1)
  
  
  
  const abadgers = [{ badgertype : badger1},{ badgertype : badger2 },{ badgertype : badger3},{ badgertype : badger4 },{ badgertype : badger5},{ badgertype : badger6 },{ badgertype : badger7},{ badgertype : badger8 },{ badgertype : badger9},{ badgertype : badger10 }]
  let i=1
  const changebb = () => {
    if(i>9){
      i=0
    }
    else{
    setSelected(abadgers[i].badgertype)
    i+=1
    }
  }

  const bb = useRef();
  
  const rbb1 = useRef();

  

  useEffect(() => {
    const dis = bb.current.offsetWidth + 100;
    const tl2 = gsap.timeline({repeat: -1, onRepeat: changebb});
    tl2.to(
      rbb1.current,
      {
        duration: 2,
        y: -10,
        ease: "elastic.inOut(1, 0.7)", 
      },
      0
    )
    tl2.to(
      rbb1.current,
      {  
        duration: 2,
        y: 350,
        ease: "elastic.inOut(1, 0.7)",
      },
      3
    );

    return() =>{
     tl2.kill();
    };

  }, []);

  return (
    <div className="aboutsec" id="about">
      <img src={lleaf} className="lleaf"/>
      <img src={rleaf} className="rleaf" />
      <img src={mrleaf} className="mrleaf" />
          <section className="afterHero" >
      <div className="afterHero1"  ref={bb}>
        <div className="hole" style={{zIndex: 2}}><img src={front} className="frontimage "/></div>
        <div className="hole"><img src={back} className="backimage " /></div> 
        <div className="hole"><img src={que} className="question " /></div> 
        <img
          ref={rbb1}
          style={{ height: "auto", width: "260px", transform: "translateY(350px)" }}
          src={selected}
        />
        
      </div>
      <div className="afterHero2" >
        
        <h2>
          <b> Badger Buddiez </b>
        </h2>
        <p>
        Badger Buddiez are a collection of 10,000 Badger Buddiez NFTs, 
        which are unique digital collectibles living on the Blockchain.
         Your Badger Buddy NFT doubles, as your club membership card grants access to 
         members only benefits. Your first access is to the Badger Sett... future areas
          and perks can be unlocked by the community through the roadmap activation. 
          Please take a look at our roadmap and follow us to the Badger Setts,
         a network of secret tunnels and dens only for the Badger Buddiez community.
        </p>
      </div>
    
    </section>
    </div>
  );
};

export default About;
