import styled from "styled-components";


export const StyledButton = styled.button`
  border-radius: 50px;
  background: #cfad91;
  white-space: nowrap;
  padding: 10px 50px;
  color: white;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #cfad91;
  }
`;



export const StyledRoundButton = styled.button`
padding: 10px;
border-radius: 100%;
border: none;
padding: 10px;
font-weight: bold;
font-size: 15px;
color: var(--primary-text);
width: 30px;
height: 30px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
transition: all 0.2s ease;
box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
-webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
-moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
:active {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
&:hover {
  transition: all 0.2s ease-in-out;
  background: #fff;
  color: #cfad91;
}
`;

export const ResponsiveWrapper = styled.div`
display: flex;
flex: 1;

border-radius: 25px;
background-color: #cfad91;
box-shadow: 0 24px 34px 0 rgba(74, 58, 255, 0.05), 0 9px 24px 0 rgba(0, 0, 0, 0.45);
flex-direction: column;
justify-content: stretched;
align-items: stretched;
width: 100%;
@media (min-width: 767px) {
  flex-direction: row;
}
`;

export const StyledLogo = styled.img`
width: 150px;
margin: 10px;
@media (min-width: 767px) {
  width: 200px;
}
transition: width 0.5s;
transition: height 0.5s;
`;

export const StyledImg = styled.img`
box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);

background-image:linear-gradient(65deg, #edb8ff, #6148ff);
padding:4px;
border-radius: 10%;
align-self: flex-end;
width: 350px;
@media (min-width: 1200px) {
  width: 500px;
}
@media (min-width: 1500px) {
  width: 600px;
}
transition: width 0.5s;
`;

export const StyledLink = styled.a`
color: white;
text-decoration: none;
`;