import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaBars } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";
import { Button } from "bootstrap";
import logo from "../../assets/logos/newbblogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...${input.substring(input.length, input.length - 3)}` : input;

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };



  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    return () =>{
      window.removeEventListener("scroll", changeNav);
    };
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to="about" onClick={toggleHome}>
            <img src={logo} className="log" />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="about"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-100}
              >
                About
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="rarity"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-180}
              >
                Rarity
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="gallery"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-200}
              >
                Gallery
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="roadmap"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-180}
              >
                Road Map
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="team"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-180}
              >
                Team
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="faq"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-180}
              >
                FAQ
              </NavLinks>
            </NavItem>
          </NavMenu>
          <NavBtn>
          {window.ethereum ?
            web3.currentProvider.selectedAddress == null ?
              <NavBtnLink to="/" className="button-primary small w-button"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}
              >
                CONNECT
              </NavBtnLink> :
              <NavBtnLink to="mint"   smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-180} className="button-primary wallet small w-button"><b>Wallet </b>- {truncate(web3.currentProvider.selectedAddress,5)}</NavBtnLink>
            :
            <NavBtnLink to="/" className="button-primary small w-button"
              onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
                getData();
              }}
            >
              CONNECT
            </NavBtnLink>

          }
          {/* <NavBtnLink to = "mint">Connect</NavBtnLink> */}
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
