import styled from "styled-components";

export const FooterContainer = styled.footer`
 background-color: var(--accent);
`;

export const FooterWrap = styled.div`
 padding: 48px 24px;
 display: flex;
 justify-content: center;
 align-items: center;
 max-width:1100px;
 margin: 0 auto;
`;

export const SocialMedia = styled.section`
 max-width: 1000px;
 width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
      flex-direction:column;
  }
`;

export const SocialLogo = styled.div`
 color: #fff;
 justify-self: start;
 cursor: pointer;
 text-decoration: none;
 font-size: 1.5rem;
 display: flex;
 align-items: center;
 margin-bottom: 16px;
 font-weight: bold;
`;

export const WebsiteRights = styled.small`
 color: #fff;
 margin-bottom: 16px;
 text-align: center;
 
`;

export const SocialIcons = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 240px;
`;

export const SocialIconLink = styled.a`
 color: #fff;
 font-size: 24px;
`;