import React from 'react';
import { FaDiscord, FaTelegram, FaTwitter } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import { FooterContainer, FooterWrap, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink} from './FooterElements';

function Footer() {
    const toggleHome = () => {
        scroll.scrollToTop();
    };
  return (
    <FooterContainer>
        <FooterWrap>
           <SocialMedia>
               <SocialMediaWrap>
                   <SocialLogo to='/' onClick={toggleHome}>Badger Buddiez</SocialLogo>
                   <WebsiteRights>
                       <a href='https://etherscan.io/address/0x71521cc57f07e5a6f86745e788ca717c0d7ab307' style={{textDecoration: 'none', color: 'white', fontWeight: 'bolder', lineHeight: '40px'}} >0x71521CC57F07E5a6f86745e788CA717C0D7ab307</a>
                      <br/> Badger Buddiez © {new Date().getFullYear()} All
                Rights Reserved.   </WebsiteRights>
                <SocialIcons>
                    <SocialIconLink href="https://twitter.com/badgerbuddiez" target="_blank" aria-label="Twitter">
                        <FaTwitter/>
                    </SocialIconLink>
                    <SocialIconLink href="https://discord.gg/HUEbNgkU2g" target="_blank" aria-label="Discord">
                        <FaDiscord />
                    </SocialIconLink>
                    <SocialIconLink href="https://t.me/BadgerBuddiez" target="_blank" aria-label="Instagram">
                        <FaTelegram />
                    </SocialIconLink>
                </SocialIcons>
               </SocialMediaWrap>
           </SocialMedia>
        </FooterWrap>
    </FooterContainer>
    );
}

export default Footer;
