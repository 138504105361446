import React, { useState } from "react";
import Slider from "react-slick";
import b1 from "../../assets/Carousel Placeholder images/bb11.webp";
import b2 from "../../assets/Carousel Placeholder images/bb12.webp";
import b3 from "../../assets/Carousel Placeholder images/bb13.webp";
import b4 from "../../assets/Carousel Placeholder images/bb14.webp";
import b5 from "../../assets/Carousel Placeholder images/bb15.webp";
import b6 from "../../assets/Carousel Placeholder images/bb16.webp";
import b7 from "../../assets/Carousel Placeholder images/bb17.webp";
import b8 from "../../assets/Carousel Placeholder images/bb18.webp";
import b9 from "../../assets/Carousel Placeholder images/bb19.webp";
import b10 from "../../assets/Carousel Placeholder images/bb20.webp";
import leaf from "../../assets/leafheading.svg";


const Gallery = () => {
  const images = [b1, b10, b2, b6, b4, b5, b8, b7, b9, b3];

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: true,
    dots: true,
    speed: 300,
    arrows: false,
    slidesToShow: 5,
    centerMode: true,
    centerPadding: 0,
    pauseOnHover: false,
    autoplaySpeed: 2000,
    autoplay: true,
    cssEase: "ease-in-out",
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="Gallery" id="gallery">
      <div style={{textAlign: "center"}}>
      <img src={leaf} className="gleaf"/>
      </div>
      <h2>Gallery</h2>
      <Slider {...settings}>
        {images.map((img, idx) => (
          <div
            className={idx === imageIndex ? "slide activeSlide" : "slide"}
            key={idx}
          >
            <img src={img} alt={img} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Gallery;
